var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar",
      visible: _vm.isManageSidebar,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-manage-sidebar", val)
      },
      hidden: _vm.resetForm
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-5 py-1"
              },
              [
                _c("h3", { staticClass: "mb-0" }, [_vm._v(" Manage Members ")]),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c(
              "validation-observer",
              { ref: "refFormObserver" },
              [
                _c(
                  "b-form",
                  {
                    staticClass: "p-2",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.handleSubmit(_vm.onSubmit)
                      },
                      reset: function($event) {
                        $event.preventDefault()
                        return _vm.resetForm($event)
                      }
                    }
                  },
                  [
                    _c(
                      "b-col",
                      { staticClass: "pb-0", attrs: { cols: "12", md: "12" } },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "input-group-merge mb-1" },
                          [
                            _c(
                              "b-input-group-prepend",
                              { attrs: { "is-text": "" } },
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "SearchIcon" }
                                })
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              attrs: { placeholder: "Search" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "vue-perfect-scrollbar",
                      {
                        staticClass:
                          "todo-task-list-wrapper list-group scroll-area min-vh-50 mx-1",
                        staticStyle: {
                          height: "75vh",
                          border: "1px solid #E5E5E5",
                          "border-radius": "4px"
                        },
                        attrs: { settings: _vm.perfectScrollbarSettings }
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "list-group" },
                          _vm._l(_vm.tasks, function(task) {
                            return _c(
                              "li",
                              {
                                key: task.id,
                                staticClass: "todo-item py-1 list-group-item",
                                class: { completed: task.isCompleted }
                              },
                              [
                                _c("b-col", { attrs: { cols: "12" } }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center "
                                      },
                                      [
                                        _c(
                                          "ul",
                                          { staticClass: "list-inline mb-0" },
                                          [
                                            _c(
                                              "li",
                                              {
                                                staticClass: "list-inline-item"
                                              },
                                              [
                                                _c("b-avatar", {
                                                  staticClass: "pull-up ",
                                                  attrs: { src: task.avatar }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass: "list-inline-item"
                                              },
                                              [_vm._v(" " + _vm._s(task.title))]
                                            ),
                                            _c(
                                              "b-badge",
                                              {
                                                staticStyle: { padding: "8px" },
                                                attrs: { variant: "light-info" }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-dark font-weight-700"
                                                  },
                                                  [_vm._v(" L ")]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ml-auto text-secondary mr-1"
                                      },
                                      [
                                        task.contact
                                          ? _c(
                                              "div",
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "mr-50",
                                                  attrs: {
                                                    icon: "TrashIcon",
                                                    size: "16"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !task.contact
                                          ? _c(
                                              "div",
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "mr-50",
                                                  attrs: {
                                                    variant: "info",
                                                    icon: "PlusIcon",
                                                    size: "16"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex mt-3 ml-1" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(255, 255, 255, 0.15)",
                                expression: "'rgba(255, 255, 255, 0.15)'",
                                modifiers: { "400": true }
                              }
                            ],
                            attrs: { type: "submit", variant: "primary" }
                          },
                          [_vm._v(" Save ")]
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(186, 191, 199, 0.15)",
                                expression: "'rgba(186, 191, 199, 0.15)'",
                                modifiers: { "400": true }
                              }
                            ],
                            staticClass: "text-primary ml-1",
                            attrs: { type: "button", variant: "outline-white" },
                            on: { click: hide }
                          },
                          [_vm._v(" Cancel ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }