<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isManageSidebar"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-manage-sidebar', val)"
      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-5 py-1">
        <h3 class="mb-0">
          Manage Members
        </h3>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver" >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <!-- Search Bar -->

          <b-col  cols="12" md="12" class="pb-0">
            <b-input-group class="input-group-merge mb-1">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input placeholder="Search" />
            </b-input-group>
          </b-col>

          <!-- Todo List -->
          <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="todo-task-list-wrapper list-group scroll-area min-vh-50 mx-1"
              style="height: 75vh;border: 1px solid #E5E5E5;border-radius: 4px;"
          >
            <ul class="list-group">
              <li
                  v-for="task in tasks"
                  :key="task.id"
                  :class="{ 'completed': task.isCompleted }"
                  class="todo-item py-1 list-group-item"
              >


                <b-col cols="12"  >
                  <div class="row">

                    <!-- 01 Total Network Practices -->

                    <div class="d-flex align-items-center ">

                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <b-avatar :src="task.avatar" class="pull-up "/>
                        </li>
                        <li class="list-inline-item"> {{ task.title }}</li>
                        <b-badge  style="padding:8px" variant="light-info">
                        <span class="text-dark font-weight-700"> L </span>
                      </b-badge>
                      </ul>
                    </div>
                    <div class="ml-auto text-secondary mr-1">

                      <div v-if="task.contact" >
                      <feather-icon
                          class="mr-50"
                          icon="TrashIcon"
                          size="16"
                      />
                    </div>
                      <div v-if="!task.contact">
                        <feather-icon
                            variant="info"
                            class="mr-50"
                            icon="PlusIcon"
                            size="16"
                        />
                      </div>
                  </div>
                  </div>



                </b-col>




              </li>
            </ul>

          </vue-perfect-scrollbar>



          <!-- Form Actions -->
          <div class="d-flex mt-3 ml-1">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
            >
              Save
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1"
                type="button"
                variant="outline-white"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>

      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>


import {tasks} from './todoTO_BE_REMOVED'
import {
  BBadge,
  BAvatar,
  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BInputGroupPrepend,
  BCol,

} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {alphaNum, email, required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'



  export default {
    methods: {
      toggle() {
        this.$emit('toggleSideBars')
      }
    },
    components: {
      BAlert,
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BInputGroup,
      BButton,
      BFormDatepicker,
      BFormCheckbox,
      vSelect,
      VuePerfectScrollbar,
      draggable,
      BRow,
      BCol,
      BInputGroupPrepend,
      BBadge,
      BAvatar,

      // Form Validation
      ValidationProvider,
      ValidationObserver
    },
    directives: {
      Ripple
    },
  model: {
    prop: 'isManageSidebar',
    event: 'update:is-manage-sidebar'
  },
  props: {
    isManageSidebar: {
      type: Boolean,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },

    data () {
      return {
        required,
        alphaNum,
        email,
        start_date: '',
        end_date: '',
        perfectScrollbarSettings: {
          maxScrollbarLength: 100
        },
        tasks: tasks
      }
    },
    setup (props, {emit}) {
      const blankUserData = {
        fullName: '',
        username: '',
        email: '',
        role: null,
        currentPlan: null,
        company: '',
        country: '',
        contact: ''
      }

      const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
      const resetuserData = () => {
        userData.value = JSON.parse(JSON.stringify(blankUserData))
      }

      const onSubmit = () => {
        store.dispatch('app-user/addUser', userData.value)
            .then(() => {
              emit('refetch-data')
              emit('update:is-manage-sidebar', false)
            })
      }

      const {
        refFormObserver,
        getValidationState,
        resetForm
      } = formValidation(resetuserData)

      return {
        userData,
        onSubmit,

        refFormObserver,
        getValidationState,
        resetForm
      }
    }
  }
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
