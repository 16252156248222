var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar",
      visible: _vm.isProfileSidebar,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-profile-sidebar", val)
      },
      hidden: _vm.resetForm
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-5 py-1"
              },
              [
                _c("h4", { staticClass: "mb-0" }),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c(
              "validation-observer",
              { ref: "refFormObserver" },
              [
                _c(
                  "b-card",
                  [
                    _c("div", { staticClass: "row g-0" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 d-flex justify-content-center" },
                        [
                          _c("b-avatar", {
                            staticClass: "badge-minimal mt-n2 ",
                            attrs: {
                              size: "130px",
                              variant: "light-primary",
                              badge: "",
                              src: require("@/assets/images/avatars/9-small.png"),
                              "badge-variant": "success"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-center mt-1"
                      },
                      [
                        _c("h2", { staticClass: " font-weight-700 " }, [
                          _vm._v("Courtney Henrry")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 d-flex justify-content-center" },
                      [
                        _c(
                          "b-alert",
                          { attrs: { variant: "primary", show: "" } },
                          [
                            _c(
                              "small",
                              {
                                staticClass: "text-dark font-weight-700 mx-1 "
                              },
                              [_vm._v("Occupational Therapist ")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-start mt-1"
                      },
                      [
                        _c("h6", { staticClass: " font-weight-700 " }, [
                          _vm._v("Personal Info")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 d-flex justify-content-start" },
                      [
                        _c("div", { staticClass: "p-2 ml-n2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start "
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "PhoneIcon" }
                              }),
                              _c("div", { staticClass: "ml-1" }, [
                                _vm._v(" 078 4914 9377")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "MailIcon" }
                              }),
                              _c("div", { staticClass: "ml-1" }, [
                                _vm._v(" courtney.henry@mp.co.uk")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "PocketIcon" }
                              }),
                              _c("div", { staticClass: "ml-1" })
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "mt-n1" }, [_c("hr")]),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-start mt-2"
                      },
                      [
                        _c("h6", { staticClass: " font-weight-700 " }, [
                          _vm._v("Professional Info")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 d-flex justify-content-start" },
                      [
                        _c("div", { staticClass: "p-2 ml-n2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start "
                            },
                            [_vm._v(" Qualification ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [_vm._v(" Speciality ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [_vm._v(" IT System ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [_vm._v(" Smart Card Number ")]
                          )
                        ]),
                        _c("div", { staticClass: "p-2 ml-n2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start "
                            },
                            [
                              _c(
                                "h5",
                                { staticClass: "mb-0 font-weight-700" },
                                [_vm._v(" MRCGP ")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [
                              _c(
                                "h5",
                                { staticClass: "mb-0 font-weight-700" },
                                [_vm._v(" Child Protection ")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [
                              _c(
                                "h5",
                                { staticClass: "mb-0 font-weight-700" },
                                [_vm._v(" EMIS WEB ")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [
                              _c(
                                "h5",
                                { staticClass: "mb-0 font-weight-700" },
                                [_vm._v(" 123456789 ")]
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-start mt-n2"
                      },
                      [
                        _c("div", { staticClass: "p-2 ml-n2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start "
                            },
                            [_vm._v(" Skills ")]
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-start mt-n2"
                      },
                      [
                        _c("div", { staticClass: "p-2 ml-n2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start "
                            },
                            [
                              _c(
                                "b-row",
                                { staticClass: "pl-0 " },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mt-1 font-weight-light",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { variant: "light-info" }
                                        },
                                        [_c("h6", [_vm._v("Dietology")])]
                                      ),
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "mr-1 my-1 my-md-0",
                                          attrs: { variant: "light-info" }
                                        },
                                        [_c("h6", [_vm._v(" Food Processing")])]
                                      ),
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { variant: "light-info" }
                                        },
                                        [_c("h6", [_vm._v(" Nutrition")])]
                                      ),
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "mr-1  mb-1 my-md-0",
                                          attrs: { variant: "light-info" }
                                        },
                                        [_c("h6", [_vm._v("Biochemistry")])]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "mt-n1" }, [_c("hr")]),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-start mt-2"
                      },
                      [
                        _c("h6", { staticClass: " font-weight-700 " }, [
                          _vm._v(" Availability Today")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 d-flex justify-content-start" },
                      [
                        _c("div", { staticClass: "p-2 ml-n2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mb-1"
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-avatar",
                                    {
                                      attrs: {
                                        variant: "success",
                                        rounded: "",
                                        size: "10"
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { icon: "CheckIcon", size: "1" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("span", { staticClass: "ml-1" }, [
                                _vm._v(" 09:00 - 12:00")
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mb-1"
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-avatar",
                                    {
                                      attrs: {
                                        variant: "success",
                                        rounded: "",
                                        size: "10"
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { icon: "CheckIcon", size: "1" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("span", { staticClass: "ml-1" }, [
                                _vm._v(" 14:00 - 18:00")
                              ])
                            ]
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "mt-n1" }, [_c("hr")]),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(255, 255, 255, 0.15)",
                            expression: "'rgba(255, 255, 255, 0.15)'",
                            modifiers: { "400": true }
                          }
                        ],
                        staticClass: " mx-1 ",
                        attrs: { variant: "primary", block: "" }
                      },
                      [_vm._v(" View Full Profile ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }