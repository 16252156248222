// import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
export const tasks = [
  {
    id: 1,
    avatar: require('@/assets/images/avatars/9-small.png'),
    title: 'Courtney Henry',
    contact: '1',

  },
  {
    id: 2,
    avatar: require('@/assets/images/avatars/2-small.png'),
    title: 'Jason Momoa',
    contact: '1',

  },
  {
    id: 3,
    avatar: require('@/assets/images/avatars/3-small.png'),
    title: 'Esther Howard',
    contact: '1',

  },
  {
    id: 4,
    avatar: require('@/assets/images/avatars/4-small.png'),
    title: 'Ronald Richard',
    contact: '1',

  },
  {
    id: 5,
    avatar: require('@/assets/images/avatars/5-small.png'),
    title: 'Cody Fisher',
    contact: '',

  },
  {
    id: 6,
    avatar: require('@/assets/images/avatars/6-small.png'),
    title: 'Kathryn Murphy',
    contact: '',

  },
  {
    id: 7,
    avatar: require('@/assets/images/avatars/7-small.png'),
    title: 'Jenny Wilson',
    contact: '',
  },
  {
    id: 8,
    avatar: require('@/assets/images/avatars/8-small.png'),
    title: 'Leslie Alexander',
    contact: '',

  },
  {
    id: 1,
    avatar: require('@/assets/images/avatars/1-small.png'),
    title: 'Jenny Wilson',
    contact: '',

  },
  {
    id: 10,
    avatar: require('@/assets/images/avatars/10-small.png'),
    title: 'arlene Robertson',
    contact: '',
  },
  {
    id: 11,
    avatar: require('@/assets/images/avatars/11-small.png'),
    title: 'Eleanar Pena',
    contact: '',

  },
  {
    id: 12,
    avatar: require('@/assets/images/avatars/12-small.png'),
    title: 'Mildred Mcleod',
    contact: '',

  },
  {
    id: 13,
    avatar: require('@/assets/images/avatars/13-small.png'),
    title: 'Jenny Wilson',
    contact: '',

  },


]

