var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown float-right mt-1" },
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            "no-caret": "",
            right: "",
            "toggle-class": "p-0 mr-1",
            variant: "link"
          },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function() {
                return [
                  _c("feather-icon", {
                    staticClass: "align-middle text-body",
                    attrs: { icon: "MoreVerticalIcon", size: "16" }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function($event) {
                  _vm.isProfileSidebar = true
                }
              }
            },
            [
              _c("feather-icon", {
                staticClass: "mr-50",
                attrs: { icon: "UserIcon", size: "16" }
              }),
              _c("span", { staticClass: "font-weight-bolder" }, [
                _vm._v(" View profile ")
              ])
            ],
            1
          ),
          _c(
            "b-dropdown-item",
            [
              _c("feather-icon", {
                staticClass: "mr-50",
                attrs: { icon: "TrashIcon", size: "16" }
              }),
              _c("span", { staticClass: "font-weight-bolder" }, [
                _vm._v(" Delete chat ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("ProfileSidebar", {
        ref: "AddLocationSideBar",
        attrs: { "is-profile-sidebar": _vm.isProfileSidebar },
        on: {
          "update:isProfileSidebar": function($event) {
            _vm.isProfileSidebar = $event
          },
          "update:is-profile-sidebar": function($event) {
            _vm.isProfileSidebar = $event
          },
          toggleSideBars: function($event) {
            return _vm.toggleSideBars()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }