var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("main", {}, [
      _c("div", [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "row g-0" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-5 col-xl-3 border-right" },
              [
                _c("div", { staticClass: " d-none d-md-block" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("b-avatar", {
                        staticClass: "badge-minimal ml-2 mr-1 ",
                        attrs: {
                          size: "40",
                          variant: "light-primary",
                          badge: "",
                          src: require("@/assets/images/avatars/13-small.png"),
                          "badge-variant": "success"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "flex-grow-1" },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "input-group-merge  my-1" },
                            [
                              _c(
                                "b-input-group-prepend",
                                { attrs: { "is-text": "" } },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "SearchIcon" }
                                  })
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "icons-search",
                                  placeholder: "Search"
                                },
                                model: {
                                  value: _vm.seachQuery,
                                  callback: function($$v) {
                                    _vm.seachQuery = $$v
                                  },
                                  expression: "seachQuery"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._m(0)
                ]),
                _vm.chats.length
                  ? _c(
                      "vue-perfect-scrollbar",
                      {
                        staticClass:
                          "scrollable-container media-list scroll-area",
                        staticStyle: { height: "640px" },
                        attrs: { settings: _vm.perfectScrollbarSettings }
                      },
                      _vm._l(_vm.chats, function(chat) {
                        return _c("b-media", { key: chat.subtitle }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "list-group-item list-group-item-action border-0 mb-n1 mt-n1"
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pb-0 ",
                                      attrs: { cols: "11" }
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center "
                                          },
                                          [
                                            chat.avatar
                                              ? _c("b-avatar", {
                                                  staticClass: "badge-minimal",
                                                  attrs: {
                                                    size: "40",
                                                    src: chat.avatar,
                                                    badge: "",
                                                    "badge-variant": chat.online
                                                  }
                                                })
                                              : _c(
                                                  "b-avatar",
                                                  {
                                                    attrs: {
                                                      size: "40",
                                                      variant: chat.bg,
                                                      text: chat.a
                                                    }
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      attrs: {
                                                        icon: chat.icon,
                                                        size: "18"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                            _c("div", { staticClass: "ml-1" }, [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 font-weight-bolder text-dark"
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(chat.name)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "small",
                                                {
                                                  staticClass: "text-secondary"
                                                },
                                                [_vm._v(_vm._s(chat.subtitle))]
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end ",
                                      attrs: { cols: "1" }
                                    },
                                    [
                                      _c("b-row", [
                                        _c("div", [
                                          _c("span", [
                                            _c("small", [
                                              _vm._v(_vm._s(chat.time))
                                            ])
                                          ])
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "ml-1" },
                                          [
                                            _c("b-avatar", {
                                              attrs: {
                                                text: chat.msg,
                                                variant: chat.color,
                                                size: "sm"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      1
                    )
                  : _vm._e(),
                !_vm.chats.length
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "d-flex align-items-center bg-white",
                            staticStyle: { height: "640px" }
                          },
                          [
                            _c("div", { staticStyle: { height: "200px" } }),
                            _c("div", { staticClass: "ml-n1" }, [
                              _c("div", { staticClass: "mb-2" }, [
                                _c("img", {
                                  staticClass: "img-fluid",
                                  attrs: {
                                    src: require("@/assets/images/pages/no-chat.png"),
                                    alt: ""
                                  }
                                })
                              ]),
                              _c("h5", [_vm._v(" No Chats ")])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "mr-1" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(255, 255, 255, 0.15)",
                            expression: "'rgba(255, 255, 255, 0.15)'",
                            modifiers: { "400": true }
                          }
                        ],
                        staticClass: "mt-2 mx-1 mb-2 ",
                        attrs: { variant: "primary", block: "" }
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-50",
                          attrs: { icon: "PlusIcon" }
                        }),
                        _vm._v(" Start New Chat ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "col-12 col-lg-7 col-xl-9" }, [
              _vm.contacts.length
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.contacts, function(contact) {
                        return _c(
                          "div",
                          { key: contact.subtitle, staticClass: "mb-1 " },
                          [
                            contact.avatar
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        " px-1 border-bottom d-none d-lg-block"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center py-1 mb-1"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "position-relative"
                                            },
                                            [
                                              _c("b-avatar", {
                                                staticClass:
                                                  "badge-minimal mr-1",
                                                attrs: {
                                                  size: "40",
                                                  src: contact.avatar,
                                                  badge: "",
                                                  "badge-variant":
                                                    contact.online
                                                }
                                              }),
                                              _c("strong", [
                                                _vm._v(_vm._s(contact.name))
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flex-grow-1 " },
                                            [_c("ChatDropDownMenu")],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              : _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        " px-1 border-bottom d-none d-lg-block"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center py-1"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "position-relative "
                                            },
                                            [
                                              _c(
                                                "b-avatar",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    size: "40",
                                                    variant: contact.bg,
                                                    text: contact.a
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: {
                                                      icon: contact.icon,
                                                      size: "18"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _c("strong", [
                                              _vm._v(_vm._s(contact.name))
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: " small" },
                                              [
                                                _c("em", [
                                                  _vm._v(
                                                    _vm._s(contact.member) +
                                                      " Members"
                                                  )
                                                ])
                                              ]
                                            )
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "flex-grow-1 mr-1" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-end "
                                                },
                                                [
                                                  _c(
                                                    "b-avatar-group",
                                                    { attrs: { size: "34px" } },
                                                    [
                                                      _vm._l(
                                                        [5, 7, 8, 9],
                                                        function(avatar) {
                                                          return _c(
                                                            "b-avatar",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover.bottom",
                                                                  value:
                                                                    avatar.fullName,
                                                                  expression:
                                                                    "avatar.fullName",
                                                                  modifiers: {
                                                                    hover: true,
                                                                    bottom: true
                                                                  }
                                                                }
                                                              ],
                                                              key:
                                                                avatar.avatar,
                                                              staticClass:
                                                                "pull-up",
                                                              attrs: {
                                                                src: require("@/assets/images/avatars/" +
                                                                  avatar +
                                                                  "-small.png")
                                                              }
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "align-self-center cursor-pointer ml-1 mb-0"
                                                        },
                                                        [_vm._v(" +2 ")]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            [_c("GroupChatDropDownMenu")],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                          ]
                        )
                      }),
                      _c("div", { staticClass: "position-relative bg-light" }, [
                        _c(
                          "div",
                          { staticClass: "chat-messages p-2 " },
                          [
                            _vm.chats.length
                              ? _c(
                                  "vue-perfect-scrollbar",
                                  {
                                    staticClass:
                                      "scrollable-container media-list scroll-area ",
                                    attrs: {
                                      settings: _vm.perfectScrollbarSettings
                                    }
                                  },
                                  _vm._l(_vm.msgs, function(msg) {
                                    return _c(
                                      "b-media",
                                      { key: msg.subtitle },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "list-group-item list-group-item-action border-0 mb-n1 mt-n1 bg-light"
                                          },
                                          [
                                            msg.id
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "chat-message-right "
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("b-avatar", {
                                                            attrs: {
                                                              size: "40",
                                                              src: msg.avatar
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-shrink-1 bg-white rounded py-1 px-1 mr-1"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "font-weight-bold "
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    msg.message
                                                                  )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "chat-message-right small text-nowrap mt-1"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(msg.time)
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _vm._e(),
                                            !msg.id
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "chat-message-left "
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("b-avatar", {
                                                            attrs: {
                                                              size: "40",
                                                              src: msg.avatar
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-shrink-1 bg-light-info rounded py-1 px-1 ml-1"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "font-weight-bold text-dark"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  msg.message
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "chat-message-right small text-nowrap mt-1 text-dark"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(msg.time)
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _vm._m(2)
                    ],
                    2
                  )
                : _vm._e(),
              !_vm.contacts.length
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "d-flex align-items-center",
                          staticStyle: { height: "800px" }
                        },
                        [
                          _c("div", { staticStyle: { height: "150px" } }),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-2 d-flex justify-content-center "
                              },
                              [
                                _c("img", {
                                  staticClass: "img-fluid",
                                  attrs: {
                                    src: require("@/assets/images/pages/start-chat.png"),
                                    alt: ""
                                  }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-center " },
                              [_c("h3", [_vm._v("Start a chat")])]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-center " },
                              [
                                _c("h4", [
                                  _vm._v(
                                    "Click on existing chat or start conversation "
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-center " },
                              [_c("h4", [_vm._v("with new contacts")])]
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-grow-0 py-3 px-4 border-top " }, [
      _c("div", { staticClass: "input-group " }, [
        _c("input", {
          staticClass: "form-control mr-1",
          attrs: { type: "text", placeholder: "Type your message" }
        }),
        _c("button", { staticClass: "btn btn-dark " }, [
          _c("div", { staticClass: "mx-1" }, [_vm._v(" Send ")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }