<template>
    <div class="dropdown float-right ">

      <b-dropdown
          no-caret
          right
          toggle-class="p-0 mr-1"
          variant="link"
      >
        <template #button-content>
          <feather-icon
              class="align-middle text-body"
              icon="MoreVerticalIcon"
              size="16"
          />
        </template>
        <b-dropdown-item
            @click="isManageSidebar = true"
        >
          <feather-icon
              class="mr-50"
              icon="UsersIcon"
              size="16"
          />
          <span class="font-weight-bolder"> Manage members </span>
        </b-dropdown-item>
        <b-dropdown-item >
          <feather-icon
              class="mr-50"
              icon="TrashIcon"
              size="16"
          />
          <span class="font-weight-bolder"> Delete chat </span>
        </b-dropdown-item>
      </b-dropdown>

      <ManageSidebar
          ref="AddLocationSideBar"
          :is-manage-sidebar.sync="isManageSidebar"
          @toggleSideBars="toggleSideBars()"
      />

    </div>

</template>

<script>

import ManageSidebar from '@/views/chat/sidebar/ManageSideBar'
import {BDropdown, BDropdownItem} from 'bootstrap-vue'

export default {
  components: {
    BDropdown, BDropdownItem, ManageSidebar,
  },
  data() {
    return {
      isManageSidebar: false,

    }
  },
  methods: {
    toggleSideBars() {
      this.isManageSidebar = !this.isManageSidebar
    },
  }
}
</script>

<style scoped>

</style>
